/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import { useMixpanel } from 'gatsby-plugin-mixpanel'
function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  console.log('snatchbot testing')
  const mixpanel = useMixpanel()
  mixpanel.init('462b1818fdcc0e98430a51754b924978')
  mixpanel.track('Hello');
  return (
    // <Helmet
    //   htmlAttributes={{
    //     lang,
    //   }}
    //   title={title}
    //   titleTemplate={`%s | ${site.siteMetadata.title}`}
    //   meta={[
    //     {
    //       name: `description`,
    //       content: metaDescription,
    //     },
    //     {
    //       property: `og:title`,
    //       content: title,
    //     },
    //     {
    //       property: `og:description`,
    //       content: metaDescription,
    //     },
    //     {
    //       property: `og:type`,
    //       content: `website`,
    //     },
    //     {
    //       name: `twitter:card`,
    //       content: `summary`,
    //     },
    //     {
    //       name: `twitter:creator`,
    //       content: site.siteMetadata.author,
    //     },
    //     {
    //       name: `twitter:title`,
    //       content: title,
    //     },
    //     {
    //       name: `twitter:description`,
    //       content: metaDescription,
    //     },
    //   ].concat(meta)}
    // />
    <Helmet>
      <script type='text/javascript'>{`window.smartlook||(function(d) {
    var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
    var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
    c.charset='utf-8';c.src='https://rec.smartlook.com/recorder.js';h.appendChild(c);
    })(document);
    smartlook('init', '3cc795b651a38ca04bb1add41ce3c53ca76c941c');`}
      </script>
      {/* <script src="https://account.snatchbot.me/script.js"></script><script>window.sntchChat.Init(139582)</script> */}
      {/* <script src="https://widget.flowxo.com/embed.js" data-fxo-widget="eyJ0aGVtZSI6IiMwMDc3YWYiLCJ3ZWIiOnsiYm90SWQiOiI1ZmViYmIzYTE2Y2UwNDAwNzNmYjkyZmIiLCJ0aGVtZSI6IiMwMDc3YWYiLCJsYWJlbCI6Ikp1cmlzdGVycmEgQm90In0sIndlbGNvbWVUZXh0IjoiSGVsbG8hIFdlbGNvbWUgdG8gSnVyaXN0ZXJyYS4gSG93IGNhbiBJIGhlbHAgeW91PyIsInBvcHVwSGVpZ2h0IjoiNjAlIn0=" async defer></script> */}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO

import styled from "styled-components"


const Header = styled.nav`
    height: 76px;
    display:grid;
    width:100%;
    grid-template-columns: 1fr 3fr 1fr;
    background-color: ${({ path }) => (path === '/' ? '#0077AF' : 'white')};
    padding:0 32px;
    font-family: 'Yantramanav';
    z-index: 1;
    width:1040px;
    margin: 0 auto;


    li {
        align-self: center;
        justify-self: center;
        
    }

    svg {
        fill: ${({ path }) => (path === '/' ? 'white' : '#121212')};
    }
    a {
        color: ${({ path }) => (path === '/' ? 'white' : '#121212')};
        text-decoration: none;
        font-weight:500;
        font-family: 'Yantramanav';
        font-size:16px;
        cursor: pointer;

        
        &:hover {
            color: #3EB0B4;
        }

        &:hover svg {
            fill: #3EB0B4;
        }
    }

    span {
        color: ${({ path }) => (path === '/' ? 'white' : '#121212')};
        font-weight:500;
        font-family: 'Yantramanav';
        font-size:16px;
        cursor: pointer;

        &:hover {
            color: #3EB0B4;
        }

        &:hover svg {
            fill: #3EB0B4;
        }

    }

 
`




export default Header;
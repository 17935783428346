import usePopper from '../../hooks/usePopper';
import React from 'react';
import './style.scss';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

function Tooltip({ config, render, children }) {
    const [triggerRef, popupRef, isExpanded, showHandler, hideHandler] = usePopper(config);

    const tooltipProps = {
        onClick: showHandler,
        // onDoubleClick: hideHandler,
        // onMouseEnter: showHandler,
        // onMouseLeave: showHandler,
        onFocus: showHandler,
        onBlur: hideHandler,
        ref: triggerRef,
    };

    return (

        <React.Fragment>
            {render(tooltipProps)}

            <div ref={popupRef} className="demo-popper" style={{ "width": "216px" }}>
                {isExpanded && <React.Fragment>
                    {/* <div data-popper-arrow className="demo-arrow"/> */}
                    <div className="demo-tooltip">
                        <ClickAwayListener onClickAway={hideHandler}>

                            <div className="demo-tooltip-content">
                                {children}
                            </div>
                        </ClickAwayListener>
                    </div>
                </React.Fragment>}
            </div>
        </React.Fragment>

    );
}

export default Tooltip;
/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import LogoDark from "./LogoDark"
import LogoLight from "./LogoLight"

import Header from "./Header";
import Footer from './Footer'
import { Link } from 'gatsby'
import "./layout.css"
import styled from "styled-components"
import Tooltip from './Tooltip'

import JTLogoBlack from '../images/JT-White-Large.svg'
import JTLogoWhite from '../images/Juristerra-Logo-Increased-White.svg'
import FooterLogo from '../images/Juristerra-Logo-Increased-White.svg'



const Button = styled.button`
  width:176px;
  font-size:16px;
  color:white;
  background-color: transparent;
  font-family: 'Yantramanav';
  font-weight:500;
  height:43px;
  align-self:center;
  border-radius:1.5px;
  border:1px solid white;
`
const Input = styled.input`
  &::placeholder { 
    color: #b9b9b9;
    opacity: 1; 
  }
  width: 350px;
  color: #121212;
  height: 43px; 
  border-radius: 2px;
  background-color: #ffffff;
  font-size: 14px;
  font-weight:400;
  padding: 0 15px;
  padding-left:15px;
  margin-right: 30px;
  border:1px solid #e2e2e2;
`

const config = {
  placement: 'bottom-start',
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, 8],
      },
    },
    // {
    //   name: 'arrow',
    //   options: {
    //     element: '[data-popper-arrow]',
    //   },
    // },

  ],
};



const Layout = ({ path, children }) => {


  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title,
  //         siteURL
  //       }
  //     }
  //   }
  // `)


  const help = (props) => (<span {...props}>Help <svg width="8px" height="8px" viewBox="0 0 7 5" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
      <path d="M3.33953451,4.67422083 C3.3970782,4.76084583 3.49236562,4.8125 3.59384053,4.8125 C3.69531543,4.8125 3.79060285,4.76084583 3.84814654,4.67422083 L6.63251901,0.5033875 C6.69810645,0.4052125 6.70553145,0.277520833 6.65200962,0.171966667 C6.59879717,0.0664125 6.49330039,0 6.37821299,0 L0.809468056,0 C0.694380661,0 0.588883882,0.0664125 0.535362055,0.171966667 C0.481840229,0.277520833 0.489574597,0.4052125 0.555162037,0.5033875 L3.33953451,4.67422083 Z" id="Down"></path>
    </defs>
    <g id="01-Homepage-V4" stroke="none" strokeWidth="1" fillRule="evenodd">
      <g id="Navigation_Hover2" transform="translate(-358.000000, -33.000000)">
        <g id="Header">
          <g id="Link-1" transform="translate(267.000000, 24.000000)">
            <g id="Atom/Icon/Arrow/Down-8px" transform="translate(90.000000, 7.000000)">
              <g id="Color-🎨" transform="translate(0.500000, 2.000000)">
                <mask id="Down-mask" >
                  <use xlinkHref="#Down"></use>
                </mask>
                <use id="Mask" fillRule="nonzero" xlinkHref="#Down"></use>
                <g id="Color-🎨/White" mask="url(#Down-mask)" >
                  <g transform="translate(-46.000000, -48.000000)" id="White">
                    <rect x="0" y="0" width="100" height="100"></rect>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg></span>);
  const products = (props) => (<span {...props}>Products <svg width="8px" height="8px" viewBox="0 0 7 5" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
      <path d="M3.33953451,4.67422083 C3.3970782,4.76084583 3.49236562,4.8125 3.59384053,4.8125 C3.69531543,4.8125 3.79060285,4.76084583 3.84814654,4.67422083 L6.63251901,0.5033875 C6.69810645,0.4052125 6.70553145,0.277520833 6.65200962,0.171966667 C6.59879717,0.0664125 6.49330039,0 6.37821299,0 L0.809468056,0 C0.694380661,0 0.588883882,0.0664125 0.535362055,0.171966667 C0.481840229,0.277520833 0.489574597,0.4052125 0.555162037,0.5033875 L3.33953451,4.67422083 Z" id="Down"></path>
    </defs>
    <g id="01-Homepage-V4" stroke="none" strokeWidth="1" fillRule="evenodd">
      <g id="Navigation_Hover2" transform="translate(-358.000000, -33.000000)">
        <g id="Header">
          <g id="Link-1" transform="translate(267.000000, 24.000000)">
            <g id="Atom/Icon/Arrow/Down-8px" transform="translate(90.000000, 7.000000)">
              <g id="Color-🎨" transform="translate(0.500000, 2.000000)">
                <mask id="Down-mask" >
                  <use xlinkHref="#Down"></use>
                </mask>
                <use id="Mask" fillRule="nonzero" xlinkHref="#Down"></use>
                <g id="Color-🎨/White" mask="url(#Down-mask)" >
                  <g transform="translate(-46.000000, -48.000000)" id="White">
                    <rect x="0" y="0" width="100" height="100"></rect>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg></span>);

  const legalProjectManagement = (props) => (<span {...props}>Legal Project Management <svg width="8px" height="8px" viewBox="0 0 7 5" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
      <path d="M3.33953451,4.67422083 C3.3970782,4.76084583 3.49236562,4.8125 3.59384053,4.8125 C3.69531543,4.8125 3.79060285,4.76084583 3.84814654,4.67422083 L6.63251901,0.5033875 C6.69810645,0.4052125 6.70553145,0.277520833 6.65200962,0.171966667 C6.59879717,0.0664125 6.49330039,0 6.37821299,0 L0.809468056,0 C0.694380661,0 0.588883882,0.0664125 0.535362055,0.171966667 C0.481840229,0.277520833 0.489574597,0.4052125 0.555162037,0.5033875 L3.33953451,4.67422083 Z" id="Down"></path>
    </defs>
    <g id="01-Homepage-V4" stroke="none" strokeWidth="1" fillRule="evenodd">
      <g id="Navigation_Hover2" transform="translate(-358.000000, -33.000000)">
        <g id="Header">
          <g id="Link-1" transform="translate(267.000000, 24.000000)">
            <g id="Atom/Icon/Arrow/Down-8px" transform="translate(90.000000, 7.000000)">
              <g id="Color-🎨" transform="translate(0.500000, 2.000000)">
                <mask id="Down-mask" >
                  <use xlinkHref="#Down"></use>
                </mask>
                <use id="Mask" fillRule="nonzero" xlinkHref="#Down"></use>
                <g id="Color-🎨/White" mask="url(#Down-mask)" >
                  <g transform="translate(-46.000000, -48.000000)" id="White">
                    <rect x="0" y="0" width="100" height="100"></rect>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg></span>);

  return (
    <>
      {/* <Header siteTitle={data.site.siteMetadata.title} /> */}
      {/* <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0 1.0875rem 1.45rem`,
        }}
      > */}
      <div style={{ backgroundColor: path === '/' ? '#0077AF' : 'white', width: "100%", height: "76px", position: "fixed" }}>


        <div style={{
          display: "grid",
          alignItems: "center",
          justifyContent: "center",
          position: "fixed",

          width: "100%"

        }}>


          <Header path={path} >
            <div style={{
              alignSelf: "center",
              justifySelf: "center",
              "paddingRight": "10px"
            }}>
              {path === '/' ? <Link to="/" style={{ "display": "flex", "justifyContent": "center" }} ><img style={{ "width": "236px", height: "50px" }} src={JTLogoWhite} /></Link> : <Link to="/" style={{ "display": "flex", "justifyContent": "center" }}><img style={{ "width": "236px", height: "50px" }} src={JTLogoBlack} /></Link>}
            </div>

            <ul style={{ "margin": "0px", "padding": "0px", "display": "grid", "gridTemplateColumns": "repeat(6, auto)", "listStyle": "none", "justifyItems": "center" }}>
              <li style={{ "display": "flex", "justifyContent": "center" }} >
                <Tooltip
                  key='top'
                  config={config}
                  render={products}
                >
                  <div style={{ "padding": "8px 4px 4px 4px" }}>
                    <Link to="/networks" >
                      Networks
                              </Link>
                  </div>
                  <div style={{ "padding": "4px 4px 8px 4px" }}>
                    <Link to="/team-management" >
                      Team management
                              </Link>

                  </div>
                </Tooltip>
              </li>
              <li style={{ "display": "flex", "justifyContent": "center" }} >
                <Tooltip
                  key='top'
                  config={config}
                  render={legalProjectManagement}
                >
                  <div style={{ "padding": "8px 4px 4px 4px" }}>
                    <Link to="/legal-project-management">
                      Overview
                              </Link>
                  </div>
                  <div style={{ "padding": "4px 4px 4px 4px" }}>
                    <Link to="/invoices" >
                      Invoices
                              </Link>

                  </div>
                  <div style={{ "padding": "4px 4px 8px 4px" }}>
                    <Link to="/payments">
                      Payments
                              </Link>

                  </div>
                </Tooltip>
              </li>
              <li style={{ "display": "flex", "justifyContent": "center" }} ><Link to="/our-team">Our Team</Link></li>

              <li style={{ "display": "flex", "justifyContent": "center" }}>
                <Tooltip
                  key='top'
                  config={config}
                  render={help}
                >
                  <div style={{ "padding": "8px 4px 4px 4px" }}>
                    <Link to="/contact-us" >
                      Contact us
                              </Link>
                  </div>
                  <div style={{ "padding": "4px 4px 8px 4px" }}>
                    <Link to="/faq" >
                      Frequently asked questions
                              </Link>

                  </div>
                </Tooltip>
              </li>
              <li style={{ "display": "flex", "justifyContent": "center" }}><Link to="/login">Login</Link></li>
            </ul>
            {path === '/' ? <Button id="on-hover-nav" >Request access</Button> : <Button style={{ color: "#0077AF", border: "1px solid #0077AF", borderRadius: "1.5px" }}>Request access</Button>}

          </Header>
        </div>
      </div>
      <main>{children}</main>
      <div style={{ backgroundColor: "#0077AF" }} >
        <Footer style={{ width: "1040px", margin: "0 auto" }}>
          <div style={{ "padding": "40px", display: "grid" }}>
            <div style={{ "display": "grid", "gridTemplateColumns": "auto 1fr", "gridGap": "16px" }}>
              <div>
                <Input placeholder="Enter your email to request access" />
                {/* <input style={{ height: "43px", paddingLeft: "15px", fontWeight: "400", borderRadius: "4px", width: "350px", fontSize: "14px"}} placeholder="Enter your email to request access" /> */}
                <Button id="on-hover-nav" style={{ "marginLeft": "16px" }}>Request access</Button>
              </div>
              <div style={{
                "display": "grid",
                "gridTemplateColumns": "30px 30px",
                "alignContent": "center",
                "justifyContent": "end",
                "gridGap": "48px"
              }}>

                <Link id="on-hover-icon"> <a style={{ "color": "#ffffff", "textDecoration": "none" }} href="https://www.linkedin.com/company/juristerra" target="_blank">
                  <svg width="28px" height="28px" viewBox="0 0 28 28" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">

                    <defs>
                      <path d="M26.4444444,0.987666667 L1.55555556,0.987666667 C0.695333333,0.987666667 0,1.68455556 0,2.54322222 L0,27.4321111 C0,28.2907778 0.695333333,28.9876667 1.55555556,28.9876667 L26.4444444,28.9876667 C27.3046667,28.9876667 28,28.2907778 28,27.4321111 L28,2.54322222 C28,1.68455556 27.3046667,0.987666667 26.4444444,0.987666667 Z M8.30511111,24.8452222 L4.14866667,24.8452222 L4.14866667,11.483 L8.30511111,11.483 L8.30511111,24.8452222 Z M6.22688889,9.65833333 C4.89533333,9.65833333 3.81888889,8.57877778 3.81888889,7.25033333 C3.81888889,5.92188889 4.89377778,4.84233333 6.22688889,4.84233333 C7.55533333,4.84233333 8.63488889,5.92033333 8.63488889,7.25033333 C8.63488889,8.58033333 7.55533333,9.65833333 6.22688889,9.65833333 Z M23.8591111,24.8452222 L19.7073333,24.8452222 L19.7073333,18.3476667 C19.7073333,16.7983333 19.6793333,14.8056667 17.5482222,14.8056667 C15.386,14.8056667 15.0577778,16.495 15.0577778,18.2387778 L15.0577778,24.8467778 L10.9091111,24.8467778 L10.9091111,11.4845556 L14.8913333,11.4845556 L14.8913333,13.3107778 L14.9488889,13.3107778 C15.5011111,12.2607778 16.8575556,11.1532222 18.8751111,11.1532222 C23.0813333,11.1532222 23.8575556,13.919 23.8575556,17.5185556 L23.8575556,24.8452222 L23.8591111,24.8452222 Z" id="linkedin"></path>
                    </defs>
                    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <g id="Organisms/Desktop/Footer/Homepage" transform="translate(-866.000000, -38.000000)">
                        <g id="Footer">
                          <g id="Atom/Icon/Social-Media/Linkedin-32px" transform="translate(864.000000, 36.000000)">
                            <g id="Color-🎨" transform="translate(2.000000, 1.012333)">
                              <mask id="linkedin-mask" fill="white">
                                <use xlinkHref="#linkedin"></use>
                              </mask>
                              <g id="Mask" fillRule="nonzero"></g>
                              <g id="Color-🎨/White" mask="url(#linkedin-mask)" fill="#FFFFFF">
                                <g transform="translate(-33.000000, -32.000000)" id="White">
                                  <rect x="0" y="0" width="100" height="100"></rect>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </a>
                </Link>

                <Link id="on-hover-icon"> <a style={{ "color": "#ffffff", "textDecoration": "none" }} href="https://twitter.com/juristerra" target="_blank">

                  <svg width="29px" height="24px" viewBox="0 0 29 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                    <defs>
                      <path d="M26.0206086,5.98120536 C26.0397924,6.24433028 26.0397924,6.50595164 26.0397924,6.76757299 C26.0397924,14.7740885 20.0588744,24 9.12853654,24 C5.76104213,24 2.63260737,23.0061396 0,21.2800401 C0.478119275,21.3356722 0.938530429,21.3552186 1.4358335,21.3552186 C4.21453287,21.3552186 6.77188072,20.3989475 8.81569306,18.767573 C6.20226949,18.7119409 4.01236515,16.9677985 3.25681864,14.5680992 C3.62426216,14.6237314 3.99318136,14.6613206 4.37980867,14.6613206 C4.91252799,14.6613206 5.44819866,14.5861421 5.94550173,14.4553314 C3.21992673,13.8929959 1.17906574,11.4557073 1.17906574,8.51171532 L1.17906574,8.43653677 C1.97150417,8.8861045 2.8908508,9.16727227 3.86479748,9.20486155 C2.26368817,8.1192833 1.21448199,6.26237314 1.21448199,4.16188448 C1.21448199,3.03721338 1.50814166,2.00576369 2.02315286,1.10662824 C4.94941991,4.77985215 9.34841238,7.18105501 14.2801242,7.44417993 C14.1886322,6.99310863 14.1325565,6.52549806 14.1325565,6.05638391 C14.1325565,2.71845633 16.782872,0 20.0765825,0 C21.7883676,0 23.3334012,0.730735497 24.4194993,1.91254229 C25.7623652,1.64941737 27.0491553,1.14271395 28.1913291,0.449567723 C27.7501018,1.85540659 26.8100957,3.03721338 25.5779056,3.7874953 C26.7746794,3.65518106 27.9345614,3.31838116 29,2.84926701 C28.1913291,4.04911665 27.1804905,5.11815562 26.0206086,5.98120536 Z" id="twitter"></path>
                    </defs>
                    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <g id="Organisms/Desktop/Footer/Homepage" transform="translate(-946.000000, -40.000000)">
                        <g id="Atom/Icon/Social-Media/Twitter-32px" transform="translate(944.000000, 36.000000)">
                          <g id="Color-🎨" transform="translate(2.000000, 4.000000)">
                            <mask id="twitter-mask" fill="White">
                              <use xlinkHref="#twitter"></use>
                            </mask>
                            <g id="Mask" fillRule="nonzero"></g>
                            <g id="Color-🎨/White" mask="url(#twitter-mask)" fill="white">
                              <g transform="translate(-31.000000, -36.000000)" id="White">
                                <rect x="0" y="0" width="100" height="100"></rect>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </a>
                </Link>
              </div>














            </div>

            <section style={{ "display": "grid", "gridTemplateColumns": "1fr 1fr 1fr 1fr", marginTop: "40px" }}>
              <ul style={{ "padding": "0px", "listStyle": "none" }}>
                <li style={{ "color": "white", "textTransform": "uppercase", "fontSize": "16px", paddingBottom: "15px" }}>Products</li>
                <li id="on-hover-link" style={{ "color": "white" }} >
                  <Link style={{ color: "white", textDecoration: "none", fontSize: "14px" }}> <a style={{ "color": "#ffffff", "textDecoration": "none" }} href="/networks">Networks</a></Link>
                </li>
                <li id="on-hover-link" style={{ "color": "white", padding: "10px 0" }} >
                  <Link style={{ color: "white", textDecoration: "none", fontSize: "14px" }}> <a style={{ "color": "#ffffff", "textDecoration": "none" }} href="/team-management">Team Management</a></Link>
                </li>

              </ul>
              <ul style={{ "padding": "0px", "listStyle": "none" }}>
                <li style={{ "color": "white", "textTransform": "uppercase", "fontSize": "16px", paddingBottom: "15px" }}>
                  Project Management
              </li>
                <li id="on-hover-link" style={{ "color": "white" }}>
                  <Link style={{ color: "white", textDecoration: "none", fontSize: "14px" }}> <a style={{ "color": "#ffffff", "textDecoration": "none" }} href="/legal-project-management">Overview</a></Link>
                </li>
                <li id="on-hover-link" style={{ "color": "white", padding: "10px 0" }} >
                  <Link style={{ color: "white", textDecoration: "none", fontSize: "14px" }}> <a style={{ "color": "#ffffff", "textDecoration": "none" }} href="/invoices">Invoices</a></Link>
                </li>
                <li id="on-hover-link" style={{ "color": "white" }} >
                  <Link style={{ color: "white", textDecoration: "none", fontSize: "14px" }}> <a style={{ "color": "#ffffff", "textDecoration": "none" }} href="/payments">Payments</a></Link>
                </li>
              </ul>
              <ul style={{ "padding": "0px", "listStyle": "none" }}>
                <li style={{ "color": "white", "textTransform": "uppercase", "fontSize": "16px", paddingBottom: "15px" }}>
                  Legal Information
              </li>
                <li id="on-hover-link" style={{ "color": "white" }}>
                  <Link style={{ color: "white", textDecoration: "none", fontSize: "14px" }}> <a style={{ "color": "#ffffff", "textDecoration": "none" }} href="/policy">Privacy Policy</a>
                  </Link>
                </li>
                <li id="on-hover-link" style={{ "color": "white", padding: "10px 0" }}>
                  <Link style={{ color: "white", textDecoration: "none", fontSize: "14px" }}> <a style={{ "color": "#ffffff", "textDecoration": "none" }} href="/terms">Terms of Service</a>
                  </Link>
                </li>
              </ul>

              <ul style={{ "padding": "0px", "listStyle": "none" }}>
                <li style={{ "color": "white", "textTransform": "uppercase", "fontSize": "16px", paddingBottom: "15px" }}>Company</li>
                <li id="on-hover-link" style={{ "color": "white" }} >
                  <Link style={{ color: "white", textDecoration: "none", fontSize: "14px" }}> <a style={{ "color": "#ffffff", "textDecoration": "none" }} href="/contact-us">Contact us</a></Link>
                </li>
                <li id="on-hover-link" style={{ "color": "white", padding: "10px 0" }} >
                  <Link style={{ color: "white", textDecoration: "none", fontSize: "14px" }}> <a style={{ "color": "#ffffff", "textDecoration": "none" }} href="/faq">Frequently asked questions</a></Link>
                </li>
              </ul>

            </section>


            <div style={{ marginTop: "32px" }}>
              <center><img style={{ width: "24%" }} src={FooterLogo} /></center>
            </div>






            {/* <div style={{ justifySelf: "center" }}>
              <LogoDark className="Logo" alt="Juristerra" />
            </div> */}

          </div>
        </Footer>
      </div>

    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
